import { Layout } from 'designsystem/components/4-habitats/Layout';
import { Vendors } from 'features/Vendors/Vendors';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { useBoundStore } from 'store';
import { Meta } from './Meta';

type NextImageProps = React.ComponentProps<typeof Image>;

const ImageOptimization = (props: NextImageProps) => {
	return <Image {...props} alt={props?.alt ?? ''} />;
};

export const LayoutFeature: React.FC<Content.PageContent> = ({ children, page, site }) => {
	const [themeClass, setThemeClass] = useState(null);
	const theme = useBoundStore((state) => state.theme);

	useEffect(() => {
		setThemeClass(theme);
	}, [theme]);
	return (
		<Layout themeClass={'sop-app ' + themeClass} imageOptimization={ImageOptimization}>
			<Meta {...site} />
			<Vendors {...page} />
			{children}
		</Layout>
	);
};
